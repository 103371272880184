import { fromLS } from "../libs/util";

const userOnLS = fromLS('auth_user', 'object');
const tokenOnLS = fromLS('auth_token');

export const authObject = {
  authorized: false,
  user: userOnLS.get(),
  token: tokenOnLS.get(),
}; 

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "login": {
      userOnLS.set(payload.user);
      tokenOnLS.set(payload.token);
      return {
        ...state,
        authorized: true,
        user: payload.user,
        token: payload.token,
      };
    }
    case "logout": {
      userOnLS.remove();
      tokenOnLS.remove();
      return {
        ...state,
        authorized: false,
        user: null,
        token: null,
      };
    }
    default:
      return state;
  }
};

export default reducer;
import { Navigate, useLocation } from "react-router-dom";
import { useStore } from "../store";

export function RequireAuth({
  children
}) {
  const { state } = useStore();
  const location = useLocation();

  if (!state.authorized) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children;
}
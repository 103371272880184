import { ReloadOutlined, RollbackOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export default function ErrorScreen() {
  const navigate = useNavigate();

  return (
    <Result
      status="500"
      title="Unexpected error occurred"
      subTitle={
        <div>
          Sorry, something went wrong.
          <br /> Please try again later.
        </div>}
      extra={[
        <Button type="primary" onClick={() => navigate(-1)} icon={<RollbackOutlined />}>
          Go back
        </Button>,
        <Button type="primary" onClick={() => navigate(0)} icon={<ReloadOutlined />}>
          Reload
        </Button>
      ]}
    />
  )
}
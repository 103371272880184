import { Spin } from "antd";
import "./Essential.scss";

export default function LoadingScreen({ tip = "" }) {
  return (
    <div className="loading-screen">
      <Spin size="large" tip={tip} />
    </div>
  );
}

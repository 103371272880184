import Compressor from "compressorjs";

export const fromLS = function fromLS(name, type = 'string') {
  return {
    get: () => {
      const item = localStorage.getItem(name);
      try {
        return item && (
          type === 'object' ? JSON.parse(item) :
            type === 'number' ? parseInt(item) :
              type === 'boolean' ? 'true' === item :
                type === 'date' ? new Date(item) :
                  type === 'string' ? item : item
        );
      } catch (e) {
        return null;
      }
    },
    set: (item) => {
      return localStorage.setItem(name,
        type === 'object' ? JSON.stringify(item) : item.toString()
      );
    },
    remove: () => {
      return localStorage.removeItem(name);
    },
  }
}

export const fromSS = function fromSS(name, type = 'string', expire) {
  return {
    get: () => {
      const item = sessionStorage.getItem(name);
      try {
        return item && (
          type === 'object' ? JSON.parse(item) :
            type === 'number' ? parseInt(item) :
              type === 'boolean' ? 'true' === item :
                type === 'date' ? new Date(item) :
                  type === 'string' ? item : item
        );
      } catch (e) {
        return null;
      }
    },
    set: (item) => {
      return sessionStorage.setItem(name,
        type === 'object' ? JSON.stringify(item) : item.toString()
      );
    },
    remove: () => {
      return sessionStorage.removeItem(name);
    },
  }
}

const source = require('./nrcList.json');
const NRC_HASHMAP = Object.keys(source)
  .reduce((dist, no) => {
    // district

    let firstLevel = source[no].reduce((hashmap, [x, y, z]) => {
      if (!hashmap[x]) hashmap[x] = [];

      hashmap[x].push([y, z])
      return hashmap;
    }, {});


    Object.keys(firstLevel)
      .forEach((f) => {
        firstLevel[f] = firstLevel[f].reduce((hashmap, [y, z]) => {
          if (!hashmap[y]) hashmap[y] = {};

          hashmap[y][z] = true
          return hashmap;
        }, {})
      })

    dist[no] = firstLevel;
    return dist;
  }, {})
/**
 *
 * @param {String} stateNum State Number
 * @param {Array} districtChars District Characters
 */
export function getValidCharacters(stateNum = '၁၂', districtChars = []) {
  const [x, y, z] = districtChars;
  if (z) return [];

  let map = NRC_HASHMAP[stateNum];

  if (x) map = map[x];
  if (y) map = map[y];

  return Object.keys(map);
  // if (districtChars.length !== 0 && districtChars.length !== 3) {
  //   const prefix = nrcList[stateNum];
  //   let validChars = [];



  //   for (let i = 0, len = districtChars.length; i < len; i++) {
  //     validChars = prefix
  //       .map((cArr) => {
  //         return condition(cArr, len) ? cArr.slice(len) : undefined;
  //       })
  //       .filter((cArr) => cArr)
  //       .flat();
  //   }

  //   return validChars;
  // }

  // return [];
}

const IS_BROWSER = typeof window !== 'undefined' && typeof window.document !== 'undefined';
const WINDOW = IS_BROWSER ? window : {};
const { FileReader } = WINDOW;
const URL = WINDOW.URL || WINDOW.webkitURL;

const isLt4M = (file) => file.size / 1024 / 1024 < 2;

export const compressFile = async (file) => {
  if (!URL || !FileReader) {
    return file;
  }
  if (!isLt4M(file)) {
    // perform compression
    const compressPromise = new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 4096,
        maxHeight: 4096,
        convertSize: 2000000,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });

    try {
      const compressResult = await compressPromise;

      // return compressed file
      return compressResult;
    } catch (err) {
      console.error(err);
    }
  }

  // return original file
  return file;
};
